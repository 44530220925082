<script>
import CustomerList from '@app/components/CustomerList.vue';

export default {
  name: 'Customers',
  head: {
    title: 'Apprenants',
  },
  components: { CustomerList },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
};
</script>

<template>
  <div>
    <CustomerList :training="currentTraining" />
  </div>
</template>
